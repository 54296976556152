@import 'utils/fonts.styl'
@import 'utils/vars.styl'
@import 'utils/mixins.styl'
@import '_sprite.styl'

*
  box-sizing border-box
  padding 0
  margin 0
  // transition: all .2s ease-in

html
  font-size baseFontSize
  height 100%
  line-height 1.3

body.checker__body
  height 100%
  color baseColor
  font 300 14px / 1.5 mainFont
  font-weight normal

h1
  margin-bottom: rem(50px)
  letter-spacing 0.16em
  text-transform uppercase
  font-weight: 700

h2, h3 
  margin: rem(10px) 0
  font-weight: 700

.info
  margin rem(20px)
  p
    margin rem(20px)

ul, li
  list-style-type none
  margin 0
  padding 0

p
  margin 0

a
  color black
  text-decoration none

audio,
canvas,
iframe,
img,
svg,
video
  vertical-align middle

textarea
  resize none

section
  position relative

input,
select,
button
  outline none

.wrapper
  display flex
  flex-direction column
  height 100%

//horizontal-center()

.content
  flex 1 0 auto
  overflow hidden

//position relative

//Модули
@import "modules/modules.styl"

// СТРАНИЦЫ
// Главная страница
@import 'pages/index/index.styl'

//links