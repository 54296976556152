center()
	display flex
	justify-content center
	align-items center

horizontal-center()
	position absolute
	left 50%
	transform translate(-50%)

absolute-all(num)
	position absolute
	top num
	bottom num
	right num
	left num

position($position, $top = null, $right = null,  $bottom = null, $left = null)
	position: $position
	if $left is a 'unit' or $left == 'auto'
		left: $left
	if $right is a 'unit' or $right == 'auto'
		right: $right
	if $top is a 'unit' or $top == 'auto'
		top: $top
	if $bottom is a 'unit' or $bottom == 'auto'
		bottom: $bottom

absolute($top = null, $right = null,  $bottom = null, $left = null)
	position(absolute, $top, $right, $bottom, $left)

fixed($top = null, $right = null,  $bottom = null, $left = null)
	position(fixed, $top, $right, $bottom, $left)

relative($top = null, $right = null,  $bottom = null, $left = null)
	position(relative, $top, $right, $bottom, $left)

rem(size)
	return (size/16)rem

size(a, b = false)
	width a
	if b
		height b
	else
		height a

for_media(breakpoints)
	conditions = ()
	for breakpoint in breakpoints
		push(conditions, media_queries[breakpoint])
	conditions = join(", ", conditions)
	@media conditions
		{block}

for_media_maxheight(var)
	condition = 'screen and (max-height: %s)' % var
	@media condition
		{block}

for_media_maxwidth(var)
	condition = 'screen and (max-width: %s)' % var
	@media condition
		{block}