

.icon {
	display: inline-block;
	width: 1em;
	height: 1em;
	fill: none;
	fill-rule nonzero
	stroke currentColor
}

.icon-design {
	font-size:(83.03/10)*1rem;
	width:(79.969/83.03)*1em;
}
.icon-dev {
	font-size:(317/10)*1rem;
	width:(329/317)*1em;
}
.icon-logo {
	font-size:(71/10)*1rem;
	width:(69/71)*1em;
}
.icon-product {
	font-size:(91/10)*1rem;
	width:(78.969/91)*1em;
}
.icon-scroll {
	font-size:(154/10)*1rem;
	width:(96/154)*1em;
}
.icon-vk {
	font-size:(112.2/10)*1rem;
	width:(112.2/112.2)*1em;
}
