@import url("https://fonts.googleapis.com/css?family=Lato:100,300,700");
.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  fill: none;
  fill-rule: nonzero;
  stroke: currentColor;
}
.icon-design {
  font-size: 8.303rem;
  width: 0.96313380705769em;
}
.icon-dev {
  font-size: 31.7rem;
  width: 1.037854889589905em;
}
.icon-logo {
  font-size: 7.1rem;
  width: 0.971830985915493em;
}
.icon-product {
  font-size: 9.1rem;
  width: 0.867791208791209em;
}
.icon-scroll {
  font-size: 15.4rem;
  width: 0.623376623376623em;
}
.icon-vk {
  font-size: 11.22rem;
  width: 1em;
}
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
html {
  font-size: 16px;
  height: 100%;
  line-height: 1.3;
}
body.checker__body {
  height: 100%;
  color: #616161;
  font: 300 14px/1.5 Lato, Arial, sans-serif;
  font-weight: normal;
}
h1 {
  margin-bottom: 3.125rem;
  letter-spacing: 0.16em;
  text-transform: uppercase;
  font-weight: 700;
}
h2,
h3 {
  margin: 0.625rem 0;
  font-weight: 700;
}
.info {
  margin: 1.25rem;
}
.info p {
  margin: 1.25rem;
}
ul,
li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
p {
  margin: 0;
}
a {
  color: #000;
  text-decoration: none;
}
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}
textarea {
  resize: none;
}
section {
  position: relative;
}
input,
select,
button {
  outline: none;
}
.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.content {
  flex: 1 0 auto;
  overflow: hidden;
}
footer {
  flex: 0 0 auto;
}
